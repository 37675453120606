/*TILE*/
.tile{
    display: flex;
    align-self: stretch;
    flex-direction: column;
    background-color: white;
    color: black;
    border-radius: 5px;
    width: 83px;
    height: 76px;
    font-weight: 700;
    margin-bottom: 25px;
    margin-right: 13.5px;
    margin-left: 13.5px;
}
.tile .tile-times{
    font-size: 18px;
    position: relative;
    width: 90%;
    text-align: right;
    height: 0;
}
.tile .tile-letter{
    font-size: 45px;
    text-align: center;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.invisible{
    visibility: hidden;
    opacity: 0;
}
/*DEMO, used in page 'INSTRUCTIONS*/
.tile-demo{
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    border-radius: 5px;
    width: 64px;
    height: 65px;
    font-weight: 700;
    margin: 10px 0 25px 0;
}
.tile-demo .tile-times{
    font-size: 18px;
    position: relative;
    width: 90%;
    text-align: right;
    height: 0;
}
.tile-demo .tile-letter{
    font-size: 33px;
    text-align: center;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.light-tile{
    background-color: rgb(51, 51, 51);
    color: white;
}
.high-contrast-dark{
    background-color: rgb(255, 102, 0) !important;
    color: white !important;
}
.high-contrast-light{
    background-color: rgb(204, 51, 204) !important;
    color: white !important;
}

@media only screen and (max-height: 670px){
    .tile{
        width: 75px;
        height: 76px;
        margin-bottom: 15px;
        margin-right: 17.5px;
        margin-left: 17.5px;
    }
}

@media only screen and (max-width: 300px){
    .tile{
        width: 65px;
        height: 66px;
        margin-bottom: 20px;
        margin-right: 7.5px;
        margin-left: 7.5px;
    }
}
@media only screen and (min-width: 301px) and (max-width: 344px){
    .tile{
        width: 69px;
        height: 70px;
        margin-bottom: 20px;
        margin-right: 10.5px;
        margin-left: 10.5px;
    }
}
@media only screen and (min-width: 345px) and (max-width: 390px){
    .tile{
        width: 77px;
        height: 74px;
        margin-bottom: 20px;
        margin-right: 11.5px;
        margin-left: 11.5px;
    }
}
