.app {
  background-color: rgb(74,74,74);
  min-height: 100vh;
  height: 100%;
  min-width: 100vw;
  width: 100%;
  overflow-y: auto;
}
.app-light{
  background-color: rgb(250, 249, 246);
}
/*LOADER CONTAINER*/
.loader-container{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  margin: 100px auto;
}
/*TOGGLE SWITCH*/
input[type="checkbox"]{
  -webkit-appearance: none;
  position: relative;
  background-color: rgb(155, 155, 155);
  width: 55px;
  height: 21px;
  border-radius: 20px;
  cursor: pointer;
}
input[type="checkbox"]::before{
  content: "";
  position: absolute;
  height: 21px;
  width: 21px;
  left: 0%;
  background-color: white;
  border-radius: 50%;
  transform: scale(1.5);
  transition: left 0.7s ease-in-out;
}
input:checked[type="checkbox"]::before {
  background-color: #fff;
  left: 70%;
}
input[type="checkbox"]:checked {
  background-color: rgb(255, 102, 0);
}
/*LAYOUT - DEFAULT: DARK*/
.layout{
  background-color: rgb(74,74,74);
  color: white;
  border: 5px solid rgb(104, 104, 104);
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
  height: 638px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  margin-left: auto;
  margin-right: auto;
  width: 330px;
  padding: 10px 20px;
}
/*LAYOUT LIGHT*/
.layout-light{
  color: black;
  background-color: rgb(250, 249, 246);
}
.layout-light #header{
  color: black;
}
.layout-light .icon-close{
  color: black;
  background-color: white;
}
.layout-light .button-home{
  color: white !important;
  background-color: rgb(74, 74, 74) !important;
  border: none;
}
.layout-light .button-radius-5{
  color: white;
  background-color: rgb(74, 74, 74);
}
.layout-light .top-10-times-button{
  color: white !important;
  background-color: rgb(74, 74, 74) !important;
}
.layout-light a{
  color: black;
}
.layout-light .card{
  color: white;
  background-color: rgb(74, 74, 74);
}
.layout-light .shuffle-button{
  color: white !important;
  background-color: black !important;
}
.layout-light .share-button{
  color: white;
  background-color: #800080 !important;
}
.layout-light .horizontal-bar{
  background-color: black;
}
.layout-light input[type="checkbox"]::before{
  background-color: black;
}
.layout-light input[type="checkbox"]:checked {
  background-color: rgb(51, 204, 0);
}
/*HIGH CONTRAST*/

/*HORIZONTAL BAR*/
.horizontal-bar{
  height: 2px;
  background-color: white;
  width: 100%;
}
/*HEADER*/
#header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  height: 50px;
  margin: 0 auto;
}
#header img{
  height: 100%;
  width: auto;
}
#header .nav-icon{
  width: 37px;
  height: 36px;
  background-size: 100% 100%;
}
/*SECTION*/
section{
  font-size: 18px;
  min-height: 300px;
}
.section-title{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}
.section-title .empty{
  width: 45px;
}
.section-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.title{
  width: 100%;
  text-align: center;
  font-weight: 700;
  margin: 0;
}
section .icon-close{
  position: relative;
  background-color: black;
  border-radius: 50%;
  width: 35px;
}
/*HOME*/
#home{
  height: 66%;
}
#home .section-content{
  height: 100%;
  justify-content: flex-start;
}
#home img{
  width: 260px;
  height: 120px;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 15px;
}
#home .button-home{
  width: 294px;
  height: 47px;
  border: 2px solid white;
  color: white;
  background-color: inherit;
  border-radius: 15px;
  margin: 0 auto;
  font-size: 1rem;
}
#home .home-bottom{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  font-size: 0.96rem;
}
#home .home-date-day{
  font-size: 1.1rem;
  margin: 0;
  margin-top: 20px;
}
#home .home-date-rest{
  font-size: 1.1rem;
  margin: 0;
}
#home .countdown{
  width: 100%;
  margin: 20px 0;
  text-align: center;
}
/*SETTINGS*/
#settings .section-content{
  width: 95%;
}
#settings .horizontal-bar{
  height: 5px;
}
#settings .number-of-words{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 25px;
  padding-top: 10px;
  margin-top: 15px;
}
#settings .number-of-words button{
  margin: 0 7px;
  color: black;
  background-color: rgb(153, 153, 153);
  font-size: 1rem;
}
#settings .number-of-words .selected-button{
  color: white;
  background-color: rgb(255, 102, 0);
}
#settings .options{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
#settings .option{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
#settings .option p{
  width: 70%;
}
#settings .feedback-email{
  background-color: inherit;
  border: none;
  width: fit-content;
  margin: 40px auto;
}
/*STATS*/
#stats #bubble{
  display: none;
  background-color: rgb(37, 37, 37);
  border-radius: 20px;
  padding: 10px;
  color: white;
  position: absolute;
  z-index: 10000;
  opacity: 1;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 3s;
}
#stats .scores{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
}
#stats .score{
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 700;
}
#stats .score-value{
  margin: 0;
  font-weight: 400;
}
#stats .total-words-found{
  display: flex;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-left: 40px;
  padding-bottom: 10px;
}
#stats .scores-played{
  display: flex;
  flex-direction: column;
  padding: 5px;
  min-height: 285px;
}
#stats .valid-words-found-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 45px;
  width: 100%;
  overflow-y: auto;
}
#stats .empty{
  width: 35px;
}
#stats .recorded-times{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}
#stats .recorded-times .title-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}
#stats .recorded-times .top-times{
  width: 100%;
}
#stats .recorded-times .time{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 10%;
  margin-bottom: 2px;
}
#stats .recorded-times .time .formatted-time{
  text-align: left;
  margin: 0;
  width: 35%;
  max-width: 120px;
  margin-right: 5px;
  font-size: 0.9rem;
}
#stats .recorded-times .time .horizontal-time-bar{
  background-color: #F57939;
  height: 20px;
}
#stats .no-games-played-msg{
  padding-top: 5px;
  margin: 0 20px;
}
#stats .daily-challenge-number{
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin: 0;
  margin-bottom: 5px;
}
#stats .stats-bottom{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 15px 0;
}
#stats .top-10-times-button{
  height: 48px;
  width: 45%;
  font-size: 0.95rem;
  border-radius: 5px;
  background-color: white;
  color: black;
}
#stats .share-button{
  background-color: #F57939;
  color: white;
  font-weight: 800;
  border-radius: 5px;
  font-size: 0.95rem;
  width: 40%;
}
/*INSTRUCTIONS*/
#instructions .valid-examples{
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 10px 0;
}
#instructions .valid-examples p{
  margin: 0;
  margin-bottom: 5px;
}
#instructions .valid-examples span{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  line-height: 0%;
  margin: 0 0.5px;
  font-weight: 700;
}
#instructions .valid-word, #instructions .valid-core-word{
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 3px;
  color: white;
  margin: 0 8px;
}
#instructions .valid-word span{
  background-color: rgb(31, 120, 224);
}
#instructions .valid-core-word span{
  background-color: rgb(82, 148, 7);
}
#instructions .tile-example{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#instructions .tile-example p{
  width: 75%;
}
#instructions .tile-example .tile{
  margin-top: 45px;
  margin-bottom: 25px;
}
/*GAME*/
#game .board{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  width: 100%;
  height: 150px;
  margin: 13px 0;
}
#game .row{
  display: flex;
  flex-direction: row;
  margin: 2px 0;
}
#game .card{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 44px;
  color: white;
  background-color: rgb(74, 74, 74);
  border: 2px solid white;
  font-size: 35px;
  line-height: 0;
}
#game .card-light{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 44px;
  color: white;
  border: none;
  background-color: rgb(74, 74, 74);
  font-size: 35px;
  line-height: 0;
}
#game .msg{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  min-height: 40px;
  font-size: 18px;
  text-align: center;
}
#game .tiles-container{
  width: 100%;
  min-height: 200px;
  margin: 0 auto;
}
#game .tiles{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  min-height: 300px;
}
#game .game-bottom{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
}
#game .shuffle-button{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: black;
  background-color: white;
}
#game .erase-button{
  height: 50px;
  width: 60px;
  border-radius: 22px;
  background-size: contain;
  cursor: pointer;
}
#game #finished{
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-height: 670px){
  .app{
    height: 690px;
  }
  .layout{
    height: 600px;
  }
}
@media only screen and (max-width: 300px){
  html{
    font-size: 16px;
  }
  .layout{
    width: 240px;
    padding: 10px 15px;
    height: 600px;
  }
  .icon-close{
    font-size: 30px;
  }
  #header{
    height: 40px;
  }
  #header .nav-icon{
    width: 31px;
    height: 80%;
  }
  section{
    font-size: 1rem;
  }
  #home img{
    width: 90%;
    height: auto;
  }
  #home .button-home{
    width: 100%;
  }
  #instructions .valid-examples span{
    width: 20px;
    height: 20px;
  }
  #instructions .tile-example p {
    width: 70%;
  }
  input[type="checkbox"]{
    width: 45px;
    height: 15px;
  }
  input[type="checkbox"]::before{
    height: 15px;
    width: 15px;
  }
  #stats .recorded-times .title-container .title{
    font-size: 0.9rem;
  }
  #stats .top-10-times-button {
    width: 50%;
  }
  #game .tiles{
    min-height: 270px;
  }
  #game .msg{
    font-size: 1rem;
  }
}
@media only screen and (min-width: 301px) and (max-width: 344px){
  html{
    font-size: 16.5px;
  }
  .layout{
    width: 270px;
    padding: 10px;
    height: 600px;
  }
  .icon-close{
    font-size: 35px;
  }
  #header{
    height: 45px;
  }
  #header .nav-icon{
    width: 35px;
    height: 80%;
  }
  section{
    font-size: 1rem;
  }
  #home img{
    width: 80%;
    height: auto;
  }
  #home .button-home{
    width: 95%;
  }
  #instructions .valid-examples span{
    width: 22px;
    height: 22px;
  }
  #instructions .tile-example p {
    width: 75%;
  }
  input[type="checkbox"]{
    width: 48px;
    height: 18px;
  }
  input[type="checkbox"]::before{
    height: 18px;
    width: 18px;
  }
  #stats .recorded-times .title-container .title{
    font-size: 0.9rem;
  }
  #stats .top-10-times-button {
    width: 50%;
  }
  #game .tiles{
    min-height: 270px;
  }
  #game .msg{
    font-size: 1rem;
  }
}
@media only screen and (min-width: 345px) and (max-width: 390px){
  html{
    font-size: 18px;
  }
  .layout{
    width: 300px;
    padding: 10px 15px;
    height: 620px;
  }
  .icon-close{
    font-size: 35px;
  }
  #header{
    height: 48px;
  }
  #header .nav-icon{
    width: 38px;
    height: 80%;
  }
  section{
    font-size: 1rem;
  }
  #home img{
    width: 80%;
    height: auto;
  }
  #home .button-home{
    width: 100%;
  }
  #instructions .valid-examples span{
    width: 25px;
    height: 25px;
  }
  #instructions .tile-example p {
    width: 75%;
  }
  input[type="checkbox"]{
    width: 48px;
    height: 18px;
  }
  input[type="checkbox"]::before{
    height: 18px;
    width: 18px;
  }
  #stats .recorded-times .title-container .title{
    font-size: 0.9rem;
  }
  #stats .top-10-times-button {
    width: 50%;
  }
  #game .tiles{
    min-height: 280px;
  }
  #game .msg{
    font-size: 1rem;
  }
}